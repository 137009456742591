import { Route, Routes } from "react-router-dom";
import "./Main.css";
import PageNotFound from "./PageNotFound/PageNotFound";
import MemoryGame from "./MemoryGame/MemoryGame";
// import MemoryGame from "./Dashboard/Games/MemoryGame/MemoryGame";


function Main(): JSX.Element {

    return (
        <div className="Main">
			<Routes>
                {/* <Route path="/" element={<Home/>}></Route> */}
                {/* <Route path="/home" element={<Home/>}></Route> */}
                <Route path="/memorygame" element={<MemoryGame/>}></Route>
                <Route path="/*" element={<MemoryGame/>}></Route>
                <Route path="/love" element={<MemoryGame/>}></Route>
			</Routes>
        </div>
    );
}

export default Main;

export default class CardModel {
  englishWord: string;
  hebrewWord: string;
  image: string;
  isFlipped: boolean;
  isMatched: boolean;

  constructor(englishWord: string, hebrewWord: string, image: string) {
    this.englishWord = englishWord;
    this.hebrewWord = hebrewWord;
    this.image = image;
    this.isFlipped = false;
    this.isMatched = false;
  }
}
// import "./Layout.css";
// import Main from "./Main/Main";

// function Layout(): JSX.Element {

//     return (
//         <div className="Layout" > 
//             {/* <Header/> */}
//             <Main/>
// 			{/* <Footer/> */}
//         </div>
//     );
// }

// export default Layout;
import React, { useEffect } from 'react';
import "./Layout.css";
import Main from "./Main/Main";

function Layout(): JSX.Element {

    useEffect(() => {
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });

        document.onkeydown = function(e) {
            if (
                e.keyCode == 123 || 
                (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) ||
                (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) ||
                (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0))
            ) {
                e.preventDefault();
                return false;
            }
        }
        
        // This cleanup function will be called when the component is unmounted
        return () => {
            document.removeEventListener('contextmenu', (e) => {
                e.preventDefault();
            });
            document.onkeydown = null;
        }
    }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

    return (
        <div className="Layout" > 
            {/* <Header/> */}
            <Main/>
            {/* <Footer/> */}
        </div>
    );
}

export default Layout;
